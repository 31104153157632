export const VcatColorPalette: { [key: string]: string; } = {
    primary: '#005EEA',
    primaryBg: '#E5EFFD',
    primaryLight: '#AAC9F8',
    primaryDark: '#004EC3',
    secondary: '#0BC86D',
    secondaryBg: '#E7FAF0',
    secondaryLight: '#AEEDCE',
    secondaryDark: '#09A75B',
    success: '#06C755',
    successBg: '#E7FAF0',
    successLight: '#ACECC6',
    successDark: '#05A647',
    warning: '#FCB400',
    warningBg: '#FFF8E5',
    warningLight: '#FEE6AA',
    warningDark: '#D29600',
    error: '#FF334B',
    errorBg: '#FFEBED',
    errorLight: '#FFBBC3',
    errorDark: '#D52A3F',
    errorDisabled: '#FFD6DB',
    text: '#212529',
    textSecondary: '#495057',
    textTertiary: '#868E96',
    textPlaceholder: '#ADB5BD',
    textDisabled: '#CED4DA',
    buttonSecondary: '#E5EFFD',
    buttonTertiary: '#F1F3F5',
    white: '#FFFFFF',
    p25: '#E5EFFD',
    p50: '#CCDFFB',
    p100: '#AAC9F8',
    p200: '#80AEF5',
    p300: '#5594F1',
    p400: '#2A79ED',
    p500: '#005EEA',
    p600: '#004EC3',
    p700: '#003F9C',
    p800: '#002F75',
    p900: '#001F4E',
    g50: '#F8F9FA',
    g100: '#F1F3F5',
    g200: '#E9ECEF',
    g300: '#DEE2E6',
    g400: '#CED4DA',
    g500: '#ADB5BD',
    g600: '#868E96',
    g700: '#495057',
    g800: '#343A40',
    g900: '#212529',
    r25: '#FFEBED',
    r50: '#FFD6DB',
    r100: '#FFBBC3',
    r200: '#FF99A5',
    r300: '#FF7787',
    r400: '#FF5569',
    r500: '#FF334B',
    r600: '#D52A3F',
    r700: '#AA2232',
    r800: '#801926',
    r900: '#551119',
};

export const VCAT_COLOR = {
    'primary': 'primary',
    'success': 'success',
    'warning': 'warning',
    'error': 'error',
    'errorDisabled': 'errorDisabled',
    'text': 'text',
    'textSecondary': 'textSecondary',
    'textTertiary': 'textTertiary',
    'textPlaceholder': 'textPlaceholder',
    'textDisabled': 'textDisabled',
    'white': 'white'
} as const;

export type VColor = (typeof VCAT_COLOR)[keyof typeof VCAT_COLOR];